import { json } from "@remix-run/node";
import { useTranslation } from "react-i18next";
import { z, ZodError, type ZodSchema } from "zod";

import { log } from "~/utils/general.ts";

const PARAMETERS_SEPARATOR = ";;";

export function useZodErrorWithParameters(error: string | undefined) {
	const { t } = useTranslation();
	let errorTranslation = undefined;
	if (!error) {
		return { errorTranslation };
	}

	if (error.includes(PARAMETERS_SEPARATOR)) {
		const parameters = error.split(PARAMETERS_SEPARATOR);
		const values = JSON.parse(parameters[1]) as
			| Record<string, string>
			| undefined;
		errorTranslation = t(parameters[0], values);
	} else {
		errorTranslation = t(error);
	}
	return { errorTranslation };
}

export const intWithinRange = (min: number, max: number) => {
	return z.coerce
		.number({ invalid_type_error: "wrongValueType" })
		.int()
		.refine(
			(val) => {
				return val >= min && val <= max;
			},
			{
				message: getWithinRangeErrorMessage(min, max),
			},
		);
};

// eslint-disable-next-line custom-rules/prefer-zod-trimmed-email
export const trimmedEmail = z
	.string()
	.email("invalidEmail")
	.trim()
	.toLowerCase();

export function getWithinRangeErrorMessage(min: number, max: number) {
	return `valueMustBeBetween${PARAMETERS_SEPARATOR}${JSON.stringify({ min, max })}`;
}

/**
 * Zod validator that coerces the string "true" to the boolean true.
 * Useful for z.union of checkbox values where the value is "true" or undefined.
 */
export const trueString = z.preprocess((val) => {
	return val === "true" ? true : val;
}, z.literal(true));

export function validateData<T>(
	zodSchema: ZodSchema<T>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any,
): T {
	try {
		return zodSchema.parse(data);
	} catch (error) {
		if (!(error instanceof ZodError)) {
			log.error("zod data validation", error as never);
			// eslint-disable-next-line @typescript-eslint/only-throw-error
			throw json(
				{ error: "Server Error", time: new Date().toISOString() },
				{ status: 500 },
			);
		}
		const errors = error.issues.map((error) => {
			return { parameter: error.path[0], message: error.message };
		});
		log.error("zod data validation", error as never);
		// eslint-disable-next-line @typescript-eslint/only-throw-error
		throw json({ errors, time: new Date().toISOString() }, { status: 422 });
	}
}
