import { useCallback } from "react";
import { useControlField, useField } from "remix-validated-form";

export function useValidatedControlField<T>(
	name: string,
	formId?: string,
): readonly [T, (value: T) => void] {
	const { validate, touched } = useField(name, { formId });

	// eslint-disable-next-line custom-rules/prefer-use-validated-control-field
	const [value, setValue] = useControlField<T>(name, formId);

	const setValueAndValidate = useCallback(
		(value: T) => {
			setValue(value);
			if (touched) {
				// timeout to ensure validation occurs with latest value
				setTimeout(() => {
					validate();
				}, 0);
			}
		},
		[setValue, touched, validate],
	);

	return [value, setValueAndValidate] as const;
}
